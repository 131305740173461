import React, { useState, useEffect } from "react";
import config from "../../../Config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { ArrowUpTrayIcon, TrashIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { Cropper } from "react-cropper";
import { useParams } from "react-router-dom";

const ImageCrop = () => {

    let params = useParams();
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AdminAccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("AdminID"));
    const [MemberType, setMemberType] = useState(localStorage.getItem("MemberType"));

    const [LoadingPackage, setLoadingPackage] = useState(false);




    const [cropperData, setCropperData] = useState([
        { img: "", selected: false, cropper: null, label: "Upload Slider Image 1", aspectRatio: 19 / 12 },
        { img: "", selected: false, cropper: null, label: "Upload Slider Image 2", aspectRatio: 19 / 12 },
        { img: "", selected: false, cropper: null, label: "Upload Slider Image 3", aspectRatio: 19 / 12 },
        { img: "", selected: false, cropper: null, label: "Upload Slider Image 4", aspectRatio: 19 / 12 },
        { img: "", selected: false, cropper: null, label: "Upload Package Image", aspectRatio: 1 },
    ]);

    useEffect(() => {

        setLoadingPackage(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            Member_Type: MemberType,
            ID: params.id,
        };
        // console.log(data);
        var api_config = {
            method: 'post',
            url: config.base_url + 'Package/GetPackagebyID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                // console.log(response.data)
                if (response.data.status_code == 1) {
                    const imageNames = response.data.SliderImages; // This is the array of image names
                    const imagePathBase = config.root + 'Images/Packages/';

                    const updatedCropperData = cropperData.map((item, index) => {
                        if (index < imageNames.length) {
                            return {
                                img: imagePathBase + imageNames[index], // Concatenate base path with image name
                                selected: true,
                                label: `Upload Package Image ${index + 1}`
                            };
                        }
                        return item; // Return unchanged item if no corresponding image
                    });

                    console.log(updatedCropperData); // Log the updated cropper data
                    setCropperData(updatedCropperData);

                    setLoadingPackage(false);
                }

                else {
                    setLoadingPackage(false);
                }
            })
            .catch(function (error) {
            });
    }, []);


    const updatePackage = () => {
        const data = {
            AccessKey,
            UserID,
            ID: params.id,
            ...cropperData.reduce((acc, cropperItem, index) => {
                if (cropperItem.selected) {
                    acc[`SliderImage${index + 1}`] = cropperItem.cropper.getCroppedCanvas().toDataURL().substring(22);
                }
                return acc;
            }, {}),
        };
        console.log(data)
        axios.post(config.base_url + "Package/UpdatePackageDetail/datat", data)
            .then(response => {
                if (response.data.status_code === 1) {
                    toast.success(response.data.status_message, { position: toast.POSITION.TOP_RIGHT });
                } else {
                    toast.error(response.data.status_message, { position: toast.POSITION.TOP_RIGHT });
                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    const MAX_IMAGE_SIZE = 4096 * 1024; // 4MB in bytes

    const handleImageChange = (index, file) => {
        if (file.size > MAX_IMAGE_SIZE) {
            toast.error("Image size must be less than 4 MB", { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setCropperData(prev => {
                const updated = [...prev];
                updated[index].img = reader.result;
                updated[index].selected = true;
                console.log(updated);
                return updated;
            });
        };
        reader.readAsDataURL(file);
    };

    const handleDrop = (index, event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) handleImageChange(index, file);
    };

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        if (file) handleImageChange(index, file);
    };

   

    const handleFileDelete = (index) => {
        setCropperData(prev => {
            const updated = [...prev];
            updated[index].img = ""; // Clear the image
            updated[index].selected = false; // Set selected to false
            updated[index].cropper = null; // Clear the cropper instance if necessary
            // console.log(updated)
            return updated;
        });
    };



    return (
        <>
            {LoadingPackage ?
                'loading...'
                :
                <div className="admin-content">
                    <div className="card card-table mb-3 mt-4">
                        <div className="card-inner">
                            <div className="form_default">
                                <div className="row align-items-end p-4 gy-3">
                                    <div className="col-md-12">
                                        <ul className="kyc-upload">
                                            {cropperData.map((cropperItem, index) => (
                                                <li key={index} style={{ width: '48%' }}>
                                                    {console.log(cropperItem.label)}
                                                    <div className={`kyc-img-card w-100 ${index < 2 ? 'white' : 'blue'} mb-3`}>
                                                        <div className="card-inner">
                                                            <div
                                                                className={`upload-box ${cropperItem.selected ? 'dragFront' : ''}`}
                                                                onDragOver={(e) => e.preventDefault()}
                                                                onDrop={(e) => handleDrop(index, e)}
                                                            >
                                                                <input
                                                                    accept="image/jpeg,image/png"
                                                                    type="file"
                                                                    onChange={(e) => handleFileChange(index, e)}
                                                                />

                                                                {cropperItem.selected ? (
                                                                    <>
                                                                        <Cropper
                                                                            style={{ marginTop: '-10px' }}
                                                                            aspectRatio={cropperItem.aspectRatio}
                                                                            zoomTo={0}
                                                                            preview=".img-preview"
                                                                            src={cropperItem.img}
                                                                            dragMode="move"
                                                                            viewMode={2}
                                                                            minCropBoxHeight={10}
                                                                            minCropBoxWidth={10}
                                                                            background={false}
                                                                            responsive={true}
                                                                            autoCropArea={1}
                                                                            checkOrientation={false}
                                                                            onInitialized={(instance) => {
                                                                                setCropperData(prev => {
                                                                                    const updated = [...prev];
                                                                                    updated[index].cropper = instance;
                                                                                    return updated;
                                                                                });
                                                                            }}
                                                                            guides={true}
                                                                        />

                                                                        <div className="delete" onClick={(e) => handleFileDelete(index, e)}>
                                                                            <TrashIcon />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <label className='thumnail'>
                                                                        <ViewfinderCircleIcon />
                                                                        <p>{cropperItem.label}</p>
                                                                    </label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="button button-primary" onClick={updatePackage}>
                                            Update Package
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ImageCrop;